<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-switch v-model="item.enable" label="Mostrar no mapa" class="my-0 py-0" />
            </v-flex>

            <v-flex xs12 md11>
                <v-text-field v-model="item.name" :rules="[rules.required]" label="Nome" class="my-0 py-0" required />
            </v-flex>

            <v-flex xs12 md5>
                <v-text-field v-model="item.cnpj" :rules="[rules.cnpj]" label="CNPJ" v-mask="'##.###.###/####-##'" return-masked-value class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md5 offset-md1>
                <v-text-field type="tel" v-model="item.contact" :rules="[rules.phone]" label="Contato" class="my-0 py-0" />
            </v-flex>

            <v-flex xs12 md5>
                <v-select v-model="item.workDays" :items="workDays" item-value="key" item-text="description" label="Aberto" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md2 offset-md1>
                <v-text-field v-model="item.openOn" :rules="[rules.time]" label="Abre" v-mask="'##:##'" return-masked-value class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md2 offset-md1 class="mb-12">
                <v-text-field v-model="item.closeOn" :rules="[rules.time]" label="Fecha" v-mask="'##:##'" return-masked-value class="my-0 py-0" />
            </v-flex>

            <v-flex xs11>
                <v-autocomplete v-model="item.idCity" :rules="[rules.required]" :items="cityList" item-value="id" item-text="name" label="Cidade" deletable-chips small-chips />
            </v-flex>

            <v-flex xs12 md5>
                <v-textarea v-model="item.address" label="Endereço" rows="3" class="my-0 py-0"></v-textarea>
            </v-flex>
            <v-flex xs12 md5 offset-md1 class="mb-12">
                <v-text-field v-model="item.houseNumber" label="Número" type="number" class="my-0 py-0" />
                <v-text-field v-model="item.cep" :rules="[rules.cep]" label="CEP" v-mask="'#####-###'" return-masked-value class="my-0 py-0" />
            </v-flex>

            <v-flex xs12 md5>
                <v-text-field v-model="item.location" :rules="[rules.required, rules.coordinate]" label="Coordenadas" class="my-0 py-0" required />
            </v-flex>
            <v-flex xs12 md5 offset-md1>
                <v-text-field v-model="item.timezone" :rules="[rules.timezone]" label="Timezone" v-mask="rules.timezoneMask" return-masked-value class="my-0 py-0" />
            </v-flex>

            <v-flex xs11>
                <v-textarea v-model="item.description" label="Descrição" rows="3" class="my-0 py-0"></v-textarea>
            </v-flex>

            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            cityList: [],
            itemClean: {
                name: "",
                idCity: "",
                // enable: true,
            },
            item: { ...this.itemClean },
            workDays: [
                { key: "Everyday", description: "Todos os dias" },
                { key: "Workdays", description: "Segunda a sexta" },
                { key: "Weekend", description: "Finais de semana" },
                { key: "Monday", description: "Segunda" },
                { key: "Tuesday", description: "Terça" },
                { key: "Wednesday", description: "Quarta" },
                { key: "Thursday", description: "Quinta" },
                { key: "Friday", description: "Sexta" },
                { key: "Saturday", description: "Sábado" },
                { key: "Sunday", description: "Domingo" },
            ],
        };
    },

    mounted() {
        this.$http
            .get("api/v2/city?sort[name]=ASC")
            .then((result) => {
                this.cityList = result;
            })
            .catch(() => {
                this.cityList = [];
            });
    },

    watch: {
        itemToEdit: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                    this.$nextTick(function () {
                        this.item = { ...this.itemClean };
                    });
                } else {
                    this.item = { ...newVal };
                    this.$nextTick(function () {
                        this.item = { ...newVal };
                    });
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>